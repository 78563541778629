





















































































































































































import CopyToClipboardButton from '@/app/components/CopyToClipboardButton.vue';
import { useFeatureFlags, useFilters } from '@/app/composable';
import store from '@/app/store';
import {
    ArchiveIcon,
    BellIcon,
    CloudDownloadIcon,
    LinkIcon,
    MinusIcon,
    PencilAltIcon,
    ReplyIcon,
    TrashIcon,
} from '@vue-hero-icons/outline';
import { CalendarIcon, UserIcon } from '@vue-hero-icons/solid';
import { PropType, Ref, computed, defineComponent } from '@vue/composition-api';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import utc from 'dayjs/plugin/utc';
import * as R from 'ramda';
import { useAssetRetrieval } from '../composable/asset-retrieval';
import { useAssetStatus } from '../composable/asset-status';
import { AssetType, AssetTypeId, ModelSourceOptions, StatusCode } from '../constants';
import { Asset } from '../types';
import AssetTypeIcon from './AssetTypeIcon.vue';
dayjs.extend(utc);
dayjs.extend(minMax);

export default defineComponent({
    name: 'AssetOverview',
    components: {
        BellIcon,
        TrashIcon,
        PencilAltIcon,
        LinkIcon,
        ArchiveIcon,
        ReplyIcon,
        UserIcon,
        CalendarIcon,
        AssetTypeIcon,
        MinusIcon,
        CloudDownloadIcon,
        CopyToClipboardButton,
    },
    props: {
        asset: {
            type: Object as PropType<Asset>,
            required: true,
        },
        filter: {
            type: String,
            required: true,
        },
        alert: {
            type: Object as PropType<{ entityId: string | number; alertId: string; enabled: boolean }>,
            required: false,
        },
    },
    setup(props, { emit, root }) {
        const { flag } = useFeatureFlags();
        const isAlertsEnabled = flag('alerts');

        const user = computed(() => store.state.auth.user);
        const isAssetCreator = computed(() => user.value && user.value.id === props.asset.createdBy.id);
        const model = computed(() => props.asset && props.asset.assetTypeId === AssetTypeId.Model);

        const updatedDate = computed(() =>
            props.asset.modifiedAt
                ? dayjs.max([dayjs(props.asset.modifiedAt), dayjs(props.asset.updatedAt)]).toString()
                : props.asset.updatedAt,
        );

        const { formatDateTime } = useFilters();

        const assetType = computed(() => {
            switch (props.asset.assetTypeId) {
                case AssetTypeId.Model:
                    return {
                        type: AssetType.Model,
                        class: 'text-cherry-700',
                    };
                case AssetTypeId.Result:
                    return {
                        type: AssetType.Result,
                        class: 'text-blue-800',
                    };
                default:
                    return {
                        type: AssetType.Dataset,
                        class: 'text-orange-800',
                    };
            }
        });

        const allFilterEnabled = computed(() => props.filter === 'all');

        const status = computed(() => props.asset?.status);
        const asset: Ref<Asset> = computed<Asset>(() => props.asset);
        const { label: assetStatusLabel, colour: statusClass } = useAssetStatus(status);
        const {
            isRetrievalDisabled,
            retrievalTooltip,
            accessibilityKey,
            belongsToUserOrOrganisation,
        } = useAssetRetrieval(asset, user);

        const isModel = computed(() => asset.value?.assetTypeId === AssetTypeId.Model);
        const isUploadedModel = computed(
            () => isModel.value && asset.value?.metadata?.model.source === ModelSourceOptions.uploaded,
        );

        const provenanceLinkTooltip = computed(() => {
            if (isUploadedModel.value) return 'The asset was uploaded by the User';

            const pipelineType = props.asset.assetTypeId === AssetTypeId.Dataset ? 'Data Check-in' : 'Analytics';
            if (props.asset.provenanceAssetWorkflowIds?.length === 0)
                return `The ${pipelineType} Pipeline, which created this asset, has been deleted.`;

            return `Navigate to ${pipelineType} Pipeline`;
        });

        const retrieve = () => {
            if (accessibilityKey.value)
                root.$router.push({
                    name: 'retrieval:create',
                    params: { assetId: `${props.asset.id}`, accessibility: accessibilityKey.value, backTo: 'assets' },
                });
        };

        const editAlert = () => {
            if (props.alert)
                root.$router.push({ name: 'alerts:edit', params: { id: props.alert.alertId, backTo: 'assets' } });
        };

        return {
            dayjs,
            emit,
            statusClass,
            isAlertsEnabled,
            assetStatusLabel,
            user,
            model,
            StatusCode,
            status,
            isAssetCreator,
            allFilterEnabled,
            AssetType,
            assetType,
            provenanceLinkTooltip,
            R,
            retrieve,
            retrievalTooltip,
            isRetrievalDisabled,
            belongsToUserOrOrganisation,
            formatDateTime,
            updatedDate,
            accessibilityKey,
            isUploadedModel,
            editAlert,
        };
    },
});
