import { defineModule } from 'direct-vuex';
import { moduleActionContext } from '../store';

export interface QueryParamsState {
    assets: Record<string, any>;
    jobs: Record<string, any>;
    workflows: Record<string, any>;
}

export const queryParamsModule = defineModule({
    namespaced: true,
    state: (): QueryParamsState => {
        return {
            assets: {},
            jobs: {},
            workflows: {},
        };
    },
    mutations: {
        SET_ASSETS_QUERY_PARAMS(state: QueryParamsState, queryParams: Record<string, any>) {
            state.assets = queryParams;
            state.jobs = {};
            state.workflows = {};
        },
        SET_JOBS_QUERY_PARAMS(state: QueryParamsState, queryParams: Record<string, any>) {
            state.assets = {};
            state.jobs = queryParams;
            state.workflows = {};
        },
        SET_WORKFLOWS_QUERY_PARAMS(state: QueryParamsState, queryParams: Record<string, any>) {
            state.assets = {};
            state.jobs = {};
            state.workflows = queryParams;
        },
    },
    actions: {
        setAssetsQueryParams({ commit }, queryParams: Record<string, any>) {
            commit('SET_ASSETS_QUERY_PARAMS', queryParams);
        },
        setJobsQueryParams({ commit }, queryParams: Record<string, any>) {
            commit('SET_JOBS_QUERY_PARAMS', queryParams);
        },
        setWorkflowsQueryParams({ commit }, queryParams: Record<string, any>) {
            commit('SET_WORKFLOWS_QUERY_PARAMS', queryParams);
        },
    },
});

export const queryParamsModuleActionContext = (context: any) => moduleActionContext(context, queryParamsModule);
