import { useAxios } from '@/app/composable';
import { useRouter } from '@/app/composable/router';
import store from '@/app/store';
import { NavigationGuard, Route } from 'vue-router';
import { ApolloAPI } from '../api';

type Next = Parameters<NavigationGuard>[2];

export const LockGuard = async (to: Route, from: Route, next: Next) => {
    const { exec: lockExec } = useAxios(true);
    const root = useRouter();
    const dcjRoute = 'data-checkin-jobs';
    lockExec(ApolloAPI.isLock(to.params?.id))
        .then((res: any) => {
            if (!res.data) {
                return next();
            } else {
                (root.app as any).$toastr.e('The Data Check-in Pipeline is locked by another user', 'Error');
                if (from.name !== dcjRoute) {
                    next({ name: dcjRoute, query: store.state.queryParams.jobs });
                }
                return false;
            }
        })
        .catch((e: { response: { status: any } }) => {
            if (e.response && e.response.status === 403) {
                (root.app as any).$toastr.e('Access to the Data Check-in Pipeline is forbidden', 'Error');
                if (from.name !== dcjRoute) {
                    root.push({ name: dcjRoute, query: store.state.queryParams.jobs }); // Use router.push to surpresses exceptions on calling next() but redirecting in a different route..
                    return false;
                }
            }
        });
};
