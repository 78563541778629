export const unitTransformations = {
    area: {
        options: [
            'Square kilometre',
            'Square metre',
            'Square mile',
            'Square yard',
            'Square foot',
            'Square inch',
            'Square centimetre',
            'Square millimetre',
            'Hectare',
            'Acre',
        ],
        target: 'Square metre',
        formula: {
            'Square kilometre': 'x * 1e+6',
            'Square metre': 'x',
            'Square mile': 'x * 2.59e+6',
            'Square yard': 'x / 1.196',
            'Square foot': 'x / 10.764',
            'Square inch': 'x / 1550',
            'Square centimetre': 'x / 10000',
            'Square millimetre': 'x / 1e+6',
            Hectare: 'x * 10000',
            Acre: 'x * 4046.86',
        },
    },
    consumption: {
        options: [
            'US Miles per gallon',
            'Miles per gallon (Imperial)',
            'Kilometre per litre',
            'Litre per 100 kilometres',
        ],
        target: 'Kilometre per litre',
        formula: {
            'US Miles per gallon': 'x / 2.352',
            'Miles per gallon (Imperial)': 'x / 2.825',
            'Kilometre per litre': 'x',
            'Litre per 100 kilometres': '100 / x',
        },
    },
    cost: {
        options: ['euro', 'usd', 'gbp'],
        target: 'euro',
        formula: {
            euro: 'x',
            usd: 'x * 0.92',
            gbp: 'x * 1.15',
        },
    },
    emissions: {
        options: ['g/km', 'kg/km'],
        target: 'g/km',
        formula: {
            'g/km': 'x',
            'kg/km': 'x / 1000',
        },
    },
    co2emissions: {
        options: ['g/wh', 'kg/kwh', 't/mwh'],
        target: 'g/wh',
        formula: {
            'g/wh': 'x',
            'kg/kwh': 'x',
            't/mwh': 'x',
        },
    },
    energy: {
        options: [
            'Gigajoule',
            'Megajoule',
            'Millijoule',
            'Joule',
            'Kilojoule',
            'Gram calorie',
            'Kilocalorie',
            'Watt hour',
            'Killowatt hour',
            'Megawatt hour',
            'Gigawatt hour',
            'Terawatt hour',
            'Electronvolt',
            'British thermal unit',
            'US therm',
            'Foot-pound',
        ],
        target: 'Killowatt hour',
        formula: {
            Gigajoule: 'x * 277.778',
            Megajoule: 'x / 3.6',
            Millijoule: 'x / 3.6e+9',
            Joule: 'x / 3.6e+6',
            Kilojoule: 'x / 3600',
            'Gram calorie': 'x / 860421',
            Kilocalorie: 'x / 860.421',
            'Watt hour': 'x / 1000',
            'Killowatt hour': 'x',
            'Megawatt hour': 'x * 1000',
            'Gigawatt hour': 'x * 1e+6',
            'Terawatt hour': 'x * 1e+9',
            Electronvolt: 'x /  2.247e+25',
            'British thermal unit': 'x / 3412.14',
            'US therm': 'x * 29.3',
            'Foot-pound': 'x / 2.655e+6',
        },
    },
    activepower: {
        options: ['Watt', 'Kilowatt', 'Megawatt', 'Gigawatt', 'P.U.'],
        target: 'Kilowatt',
        formula: {
            Watt: 'x / 1000',
            Kilowatt: 'x',
            Megawatt: 'x * 1000',
            Gigawatt: 'x * 1e+6',
            'P.U.': 'x',
        },
    },
    reactivepower: {
        options: ['Var', 'KVar', 'MVar', 'GVar', 'P.U.'],
        target: 'KVar',
        formula: {
            Var: 'x / 1000',
            KVar: 'x',
            MVar: 'x * 1000',
            GVar: 'x * 1e+6',
            'P.U.': 'x',
        },
    },
    reactiveenergy: {
        options: ['Varh', 'kVarh', 'MVarh'],
        target: 'kVarh',
        formula: {
            Varh: 'x / 1000',
            kVarh: 'x',
            MVarh: 'x * 1000',
        },
    },
    apparentpower: {
        options: ['VA', 'KVA', 'MVA', 'GVA', 'P.U.'],
        target: 'KVA',
        formula: {
            VA: 'x / 1000',
            KVA: 'x',
            MVA: 'x * 1000',
            GVA: 'x * 1e+6',
            'P.U.': 'x',
        },
    },
    current: {
        options: ['Milliampere', 'Ampere', 'Kiloampere', 'P.U.'],
        target: 'Ampere',
        formula: {
            Milliampere: 'x / 1000',
            Ampere: 'x',
            Kiloampere: 'x * 1000',
            'P.U.': 'x',
        },
    },
    voltage: {
        options: ['Millivolt', 'Volt', 'Kilovolt', 'P.U.'],
        target: 'Volt',
        formula: {
            Millivolt: 'x / 1000',
            Volt: 'x',
            Kilovolt: 'x * 1000',
            'P.U.': 'x',
        },
    },
    frequency: {
        options: ['Hertz', 'Kilohertz', 'Megahertz', 'Gigahertz', 'P.U.'],
        target: 'Hertz',
        formula: {
            Hertz: 'x',
            Kilohertz: 'x * 1000',
            Megahertz: 'x * 1e+6',
            Gigahertz: 'x * 1e+9',
            'P.U.': 'x',
        },
    },
    humidity: {
        options: ['Dew Point'],
        target: 'Dew Point',
        formula: {
            'Dew Point': 'x',
        },
    },
    irradiance: {
        options: ['W/m2'],
        target: 'W/m2',
        formula: {
            'W/m2': 'x',
        },
    },
    illuminance: {
        options: ['lux'],
        target: 'lux',
        formula: {
            lux: 'x',
        },
    },
    indoorquality: {
        options: ['ppm', 'ppb'],
        target: 'ppm',
        formula: {
            ppm: 'x',
            ppb: 'x / 1000',
        },
    },
    direction: {
        options: ['degree', 'radian'],
        target: 'degree',
        formula: {
            degree: 'x',
            radian: 'x * 57.296',
        },
    },
    length: {
        options: ['Kilometre', 'Metre', 'Centimetre', 'Millimetre', 'Mile', 'Yard', 'Foot', 'Inch', 'Nautical Mile'],
        target: 'Kilometre',
        formula: {
            Kilometre: 'x',
            Metre: 'x / 1000',
            Centimetre: 'x / 100000',
            Millimetre: 'x / 1e+6',
            Mile: 'x * 1.609',
            Yard: 'x / 1093.61',
            Foot: 'x / 3280.84',
            Inch: 'x / 39370.1',
            'Nautical Mile': 'x * 1.852',
        },
    },
    mass: {
        options: [
            'Tonne',
            'Kilogram',
            'Gram',
            'Milligram',
            'Microgram',
            'Imperial ton',
            'US ton',
            'Stone',
            'Pound',
            'Ounce',
        ],
        target: 'Kilogram',
        formula: {
            Tonne: 'x * 1000',
            Kilogram: 'x',
            Gram: 'x / 1000',
            Milligram: 'x / 1e+6',
            Microgram: 'x / 1e+9',
            'Imperial ton': 'x * 1016.05',
            'US ton': 'x * 907.185',
            Stone: 'x * 6.35',
            Pound: 'x / 2.205',
            Ounce: 'x / 35.274',
        },
    },
    percentage: {
        options: ['%'],
        target: '%',
        formula: {
            '%': 'x',
        },
    },
    pressure: {
        options: ['Atmosphere', 'Bar', 'Millibar', 'Pascal', 'Pound-force per square inch', 'mm Hg', 'Torr'],
        target: 'Bar',
        formula: {
            Atmosphere: 'x * 1.013',
            Bar: 'x',
            Millibar: 'x / 1000',
            Pascal: 'x / 100000',
            'Pound-force per square inch': 'x / 14.504',
            'mm Hg': 'x / 750.062',
            Torr: 'x / 750.062',
        },
    },
    speed: {
        options: ['Miles per hour', 'Foot per second', 'Metre per second', 'Kilometre per hour', 'Knot'],
        target: 'Metre per second',
        formula: {
            'Miles per hour': 'x / 2.237',
            'Foot per second': 'x / 3.281',
            'Metre per second': 'x',
            'Kilometre per hour': 'x / 3.6',
            Knot: 'x / 1.944',
        },
    },
    temperature: {
        options: ['Celsius', 'Fahrenheit', 'Kelvin'],
        target: 'Celsius',
        formula: {
            Celsius: 'x',
            Fahrenheit: '(x - 32) * 5 / 9',
            Kelvin: 'x - 273.15',
        },
    },
    time: {
        options: [
            'Nanosecond',
            'Microsecond',
            'Millisecond',
            'Second',
            'Minute',
            'Hour',
            'Day',
            'Week',
            'Month',
            'Year',
            'Decade',
            'Century',
        ],
        target: 'Minute',
        formula: {
            Nanosecond: 'x / 6e+10',
            Microsecond: 'x / 6e+7',
            Millisecond: 'x / 60000',
            Second: 'x / 60',
            Minute: 'x',
            Hour: 'x * 60',
            Day: 'x * 1440',
            Week: 'x * 10080',
            Month: 'x * 43800',
            Year: 'x * 525600',
            Decade: 'x * 5.256e+6',
            Century: 'x * 5.256e+7',
        },
    },
    volume: {
        options: [
            'US liquid gallon',
            'US liquid quart',
            'US liquid pint',
            'US legal cup',
            'US fluid ounce',
            'US tablespoon',
            'US teaspoon',
            'Cubic metre',
            'Litre',
            'Millilitre',
            'Imperial gallon',
            'Imperial quart',
            'Imperial pint',
            'Imperial cup',
            'Imperial fluid ounce',
            'Imperial tablespoon',
            'Imperial teaspoon',
            'Cubic foot',
            'Cubic inch',
        ],
        target: 'Litre',
        formula: {
            'US liquid gallon': 'x * 3.785',
            'US liquid quart': 'x / 1.057',
            'US liquid pint': 'x / 2.113',
            'US legal cup': 'x / 4.167',
            'US fluid ounce': 'x / 33.814',
            'US tablespoon': 'x / 67.628',
            'US teaspoon': 'x / 202.884',
            'Cubic metre': 'x * 1000',
            Litre: 'x',
            Millilitre: 'x / 1000',
            'Imperial gallon': 'x * 4.546',
            'Imperial quart': 'x * 1.137',
            'Imperial pint': 'x / 1.76',
            'Imperial cup': 'x / 3.52',
            'Imperial fluid ounce': 'x / 35.195',
            'Imperial tablespoon': 'x / 56.312',
            'Imperial teaspoon': 'x / 168.936',
            'Cubic foot': 'x * 28.317',
            'Cubic inch': 'x / 61.024',
        },
    },
    noise: {
        options: ['Decibel'],
        target: 'Decibel',
        formula: {
            Decibel: 'x',
        },
    },
    energyprice: {
        options: ['euro/KWh', 'euro/MWh'],
        target: 'euro/KWh',
        formula: {
            'euro/KWh': 'x',
            'euro/MWh': 'x * 1000',
        },
    },
    resistance: {
        options: ['Ohm', 'P.U.'],
        target: 'Ohm',
        formula: {
            Ohm: 'x',
            'P.U.': 'x',
        },
    },
    conductance: {
        options: ['Siemens', 'Microsiemens', 'P.U.'],
        target: 'Siemens',
        formula: {
            Siemens: 'x',
            Microsiemens: 'x / 1e+6',
            'P.U.': 'x',
        },
    },
    ratedcapacity: {
        options: ['mAH'],
        target: 'mAH',
        formula: {
            mAH: 'x',
        },
    },
    efficiency: {
        options: ['Wh/km'],
        target: 'Wh/km',
        formula: {
            'Wh/km': 'x',
        },
    },
    soundpower: {
        options: ['Watt'],
        target: 'Watt',
        formula: {
            Watt: 'x',
        },
    },
    flowrate: {
        options: ['ft3/s', 'ft3/min', 'm3/s', 'm3/min'],
        target: 'ft3/min',
        formula: {
            'ft3/s': 'x * 60',
            'ft3/min': 'x',
            'm3/s': 'x * 2118.88',
            'm3/min': 'x * 35.3147',
        },
    },
    thermalmass: {
        options: ['J/°C', 'J/K'],
        target: 'J/°C',
        formula: {
            'J/°C': 'x',
            'J/K': 'x',
        },
    },
    thermalheat: {
        options: ['Watt Per Square Meter Per Degree Celsius (W/m2-°C)'],
        target: 'Watt Per Square Meter Per Degree Celsius (W/m2-°C)',
        formula: {
            'Watt Per Square Meter Per Degree Celsius (W/m2-°C)': 'x',
        },
    },
    electricalcapacitance: {
        options: ['farad', 'millifarad', 'microfarad', 'nanofarad', 'picofarad'],
        target: 'farad',
        formula: {
            farad: 'x',
            millifarad: 'x / 1000',
            microfarad: 'x / 1e+6',
            nanofarad: 'x / 1e+9',
            picofarad: 'x / 1e+12',
        },
    },
};
